import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Observable, combineLatest, map, switchMap, tap } from 'rxjs';

import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { EnzoComponentsModule } from '@enzo';
import { EDamageInteriorType, EDamageType, EVehicleCategory } from '@caronsale/cos-vehicle-models';
import { I18nGeneralPipesModule, I18nVehiclePartPipe } from '@caronsale/frontend-pipes';
import { EEnzoDialogResult, EnzoDialogService } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';
import {
  EducationalModalComponent,
  IEducationalMessageParams,
  dialogPerLocationMap,
} from '@cosBuyer/purchases/complaint-form/partials/components/educational-modal/educational-modal.component';

import { LOCATIONS_MAP, VEHICLE_DAMAGE_LOCATIONS, VehicleDamageLocation, VehicleDamages, VehicleDamagesService } from '../vehicle-damages.service';

type Marker = {
  location: VehicleDamageLocation;
  hasDamages: boolean;
  damageNumber: number;
  isDamage: boolean;
  hasAddedDamages: boolean;
};

interface IVm {
  markers: Marker[];
  selectedLocation: VehicleDamageLocation;
}

type availableImages = 'sedan' | 'pickup' | 'van' | 'compact' | 'suv' | 'coupe';

@Component({
  selector: 'app-bird-view',
  standalone: true,
  imports: [CommonModule, EnzoComponentsModule, TranslateModule, NgOptimizedImage, MatTooltipModule, I18nGeneralPipesModule],
  providers: [I18nVehiclePartPipe],
  templateUrl: './bird-view.component.html',
  styleUrls: ['./bird-view.component.scss'],
})
export class BirdViewComponent {
  @Input() public vehicleCategory: EVehicleCategory;
  @Input() public editView: boolean = false;
  @Input() public displayAllDamages: boolean = false;
  @Input() public displayEducationalDialog: boolean = true;

  public categoryImageMap: Record<EVehicleCategory, availableImages> = {
    [EVehicleCategory.UNKNOWN]: 'sedan',
    [EVehicleCategory.SEDAN]: 'sedan',
    [EVehicleCategory.PICK_UP]: 'pickup',
    [EVehicleCategory.FLATBED_TRUCK]: 'pickup',
    [EVehicleCategory.VAN]: 'van',
    [EVehicleCategory.MULTIVAN]: 'van',
    [EVehicleCategory.COMPACT]: 'compact',
    [EVehicleCategory.SUV]: 'suv',
    [EVehicleCategory.STATION]: 'suv',
    [EVehicleCategory.COUPE]: 'coupe',
    [EVehicleCategory.ROADSTER_CONVERTIBLE]: 'coupe',
  };

  public LOCATIONS_MAP = LOCATIONS_MAP;
  private readonly DAMAGE_TYPES: (EDamageType | EDamageInteriorType)[] = [EDamageType.HIT, EDamageType.DEFORMATION, EDamageType.CRACK, EDamageType.BUMP];

  public vm$: Observable<IVm> = combineLatest({
    markers: this.vehicleDamagesService.allVehicleDamages$.pipe(
      // TODO: remove the bellow map whenever component is migrated to signals
      // choose between allVehicleDamages$ and vehicleDamages$ observables instead, based on the displayAllDamages input using computed
      map(allDamages =>
        this.displayAllDamages
          ? allDamages
          : VEHICLE_DAMAGE_LOCATIONS.reduce(
              (vehicleDamages, location) => ({
                ...vehicleDamages,
                [location]: allDamages[location].filter(({ urlToImage, urlToImages }) => urlToImage || urlToImages?.length),
              }),
              {} as VehicleDamages,
            ),
      ),
      // TODO: remove the above map whenever component is migrated to signals
      map(vehicleDamages =>
        VEHICLE_DAMAGE_LOCATIONS.filter(key => {
          return this.editView ? true : Boolean(vehicleDamages[key].length);
        }).map(key => ({
          location: key,
          damageNumber: vehicleDamages[key].length,
          hasDamages: Boolean(vehicleDamages[key].length),
          hasAddedDamages: vehicleDamages[key].some(damage => !damage.uuid),
          isDamage: vehicleDamages[key].some(damage => damage.types.some(type => this.DAMAGE_TYPES.includes(type))),
        })),
      ),
    ),
    selectedLocation: this.vehicleDamagesService.userSelectedLocation$.pipe(
      switchMap(location => {
        if (location && this.displayAllDamages) {
          return this.vehicleDamagesService.userSelectedLocation$;
        }
        return this.vehicleDamagesService.selectedLocation$;
      }),
    ),
  });

  public constructor(
    private vehicleDamagesService: VehicleDamagesService,
    public i18nVehiclePartPipe: I18nVehiclePartPipe,
    private enzoDialogService: EnzoDialogService,
  ) {}

  public trackByKey(_: number, marker: Marker): VehicleDamageLocation {
    return marker.location;
  }

  public handleMarkerClick(event: Event, marker: Marker, addDamage = false): void {
    if (marker.hasDamages && !addDamage) {
      return this.vehicleDamagesService.setSelectedLocation(marker.location);
    }
    event.stopPropagation();

    if (!this.displayEducationalDialog) {
      this.vehicleDamagesService.setSelectedLocation(marker.location);
      this.vehicleDamagesService.setEditState(true);
      return;
    }

    this.enzoDialogService
      .openModal<EEnzoDialogResult, IEducationalMessageParams, EducationalModalComponent>(EducationalModalComponent, {
        data: { claimStatuses: dialogPerLocationMap[marker.location] },
        disableClose: true,
      })
      .pipe(
        tap(result => {
          if (result === EEnzoDialogResult.RIGHT) {
            this.vehicleDamagesService.setSelectedLocation(marker.location);
            this.vehicleDamagesService.setEditState(true);
          }
        }),
      )
      .subscribe();
  }
}
