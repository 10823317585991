import { Injectable } from '@angular/core';
import { IAuction, ModelUtils } from '@caronsale/cos-models';
import { EVehicleInteriorDamagePart, EVehiclePart, IVehicleDamage } from '@caronsale/cos-vehicle-models';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, filter, map, shareReplay } from 'rxjs';

export type VehicleDamageLocation =
  | 'bumper-front'
  | 'headlight-front-right'
  | 'front-wheel-right'
  | 'door-front-right'
  | 'door-rear-right'
  | 'rear-wheel-right'
  | 'headlight-rear-right'
  | 'bumper-rear'
  | 'headlight-rear-left'
  | 'rear-wheel-left'
  | 'door-rear-left'
  | 'door-front-left'
  | 'front-wheel-left'
  | 'headlight-front-left'
  | 'hood'
  | 'front-windshield'
  | 'roof'
  | 'rear-windshield';

export type VehicleDamages = {
  [key in VehicleDamageLocation]: IVehicleDamage[];
};

export const VEHICLE_DAMAGE_LOCATIONS: VehicleDamageLocation[] = [
  'bumper-front',
  'headlight-front-right',
  'front-wheel-right',
  'door-front-right',
  'door-rear-right',
  'rear-wheel-right',
  'headlight-rear-right',
  'bumper-rear',
  'headlight-rear-left',
  'rear-wheel-left',
  'door-rear-left',
  'door-front-left',
  'front-wheel-left',
  'headlight-front-left',
  'hood',
  'front-windshield',
  'roof',
  'rear-windshield',
];

export const LOCATIONS_MAP: Record<VehicleDamageLocation, (EVehiclePart | EVehicleInteriorDamagePart)[]> = {
  'bumper-front': [EVehiclePart.BUMPER_FRONT],
  'headlight-front-right': [EVehiclePart.HEADLIGHT_FRONT_RIGHT],
  'front-wheel-right': [EVehiclePart.FRONT_WHEEL_RIGHT, EVehiclePart.FENDER_FRONT_RIGHT],
  'door-front-right': [EVehiclePart.DOOR_FRONT_RIGHT, EVehiclePart.SILL_RIGHT],
  'door-rear-right': [EVehiclePart.DOOR_REAR_RIGHT],
  'rear-wheel-right': [EVehiclePart.REAR_WHEEL_RIGHT, EVehiclePart.SIDE_PART_REAR_RIGHT],
  'headlight-rear-right': [EVehiclePart.HEADLIGHT_REAR_RIGHT],
  'bumper-rear': [EVehiclePart.BUMPER_REAR, EVehiclePart.TRUNK],
  'headlight-rear-left': [EVehiclePart.HEADLIGHT_REAR_LEFT],
  'rear-wheel-left': [EVehiclePart.REAR_WHEEL_LEFT, EVehiclePart.SIDE_PART_REAR_LEFT],
  'door-rear-left': [EVehiclePart.DOOR_REAR_LEFT],
  'door-front-left': [EVehiclePart.DOOR_FRONT_LEFT, EVehiclePart.SILL_LEFT],
  'front-wheel-left': [EVehiclePart.FRONT_WHEEL_LEFT, EVehiclePart.FENDER_FRONT_LEFT],
  'headlight-front-left': [EVehiclePart.HEADLIGHT_FRONT_LEFT],
  hood: [EVehiclePart.HOOD, EVehiclePart.GENERAL],
  'front-windshield': [EVehiclePart.FRONT_WINDOW],
  roof: [
    EVehiclePart.ROOF,
    EVehiclePart.INTERIOR,
    EVehiclePart.OTHER,
    EVehiclePart.CONVERTIBLE_TOP,
    ...(ModelUtils.getEnumValues(EVehicleInteriorDamagePart) as EVehicleInteriorDamagePart[]),
  ],
  'rear-windshield': [EVehiclePart.REAR_WINDOW, EVehiclePart.TAILGATE],
};

@Injectable()
export class VehicleDamagesService {
  private auctionSubject: BehaviorSubject<IAuction> = new BehaviorSubject<IAuction>(null);
  private selectedLocationSubject: BehaviorSubject<VehicleDamageLocation> = new BehaviorSubject<VehicleDamageLocation>(null);
  private isEditing = new BehaviorSubject<boolean>(false);
  public firstEnabledLocation: VehicleDamageLocation;

  public allVehicleDamages$: Observable<VehicleDamages> = this.auctionSubject.pipe(
    filter(Boolean),
    map(auction =>
      VEHICLE_DAMAGE_LOCATIONS.reduce(
        (vehicleDamages, location) => ({
          ...vehicleDamages,
          [location]: auction.associatedVehicle.damages.filter(({ location: damageLocation }) => LOCATIONS_MAP[location].includes(damageLocation)),
        }),
        {} as VehicleDamages,
      ),
    ),
    shareReplay(1),
  );

  public vehicleDamages$: Observable<VehicleDamages> = this.allVehicleDamages$.pipe(
    map(allDamages =>
      VEHICLE_DAMAGE_LOCATIONS.reduce(
        (vehicleDamages, location) => ({
          ...vehicleDamages,
          [location]: allDamages[location].filter(({ urlToImage, urlToImages }) => urlToImage || urlToImages?.length),
        }),
        {} as VehicleDamages,
      ),
    ),
  );

  public auction$: Observable<IAuction> = this.auctionSubject.asObservable();
  public isEditing$: Observable<boolean> = this.isEditing.asObservable();
  public selectedLocation$: Observable<VehicleDamageLocation> = combineLatest({
    userSelectedLocation: this.selectedLocationSubject.asObservable(),
    vehicleDamages: this.vehicleDamages$,
  }).pipe(
    map(({ userSelectedLocation, vehicleDamages }) =>
      vehicleDamages[userSelectedLocation]?.length ? userSelectedLocation : this.getFirstEnabledLocation(vehicleDamages),
    ),
    distinctUntilChanged(),
    shareReplay(1),
  );
  public userSelectedLocation$: Observable<VehicleDamageLocation> = combineLatest({
    selectedLocation: this.selectedLocation$,
    userSelectedLocation: this.selectedLocationSubject.asObservable(),
  }).pipe(map(({ selectedLocation, userSelectedLocation }) => (userSelectedLocation ? userSelectedLocation : selectedLocation)));

  public getFirstEnabledLocation(vehicleDamages: VehicleDamages): VehicleDamageLocation {
    const firstEnabledLocation = Object.keys(vehicleDamages).find(location => vehicleDamages[location].length) as VehicleDamageLocation;
    return (this.firstEnabledLocation = firstEnabledLocation);
  }

  public setAuction(auction: IAuction) {
    this.auctionSubject.next(auction);
  }

  public setSelectedLocation(location: VehicleDamageLocation) {
    this.selectedLocationSubject.next(location);
  }

  public setEditState(isEditing: boolean) {
    this.isEditing.next(isEditing);
  }
}
